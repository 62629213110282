import LocationCard from "../../cards/locationcard";
import { collection, getDocs } from "firebase/firestore"
import { useEffect, useState } from "react"
import { db } from "../../firebase/firebase" 
import { StoresData } from "../../data/storesdata";

const Panel = ({zipcode, setZipcode, setLat, setLng, stores, setStores, setCenter, currIndex, setCurrIndex, foundStores, setFoundStores, type, setType, withZipCode, setWithZipCode, filterFoundStores, setFilterFoundStores}) => {
    // const [foundStores, setFoundStores] = useState(null)
    const [selectedItem, setSelectedItem] = useState("1")
    const [openPanel, setOpenPanel] =  useState(false)
    const [practiceData, setPracticeData] = useState(null)
    const [selectedValue, setSelectedValue] = useState("")

    useEffect(() => {
        setPracticeData(stores)
    },)
    // for input field
    function handleChange (e) {
        setZipcode(e.target.value)
    }

    // for button
    function handleClick (e) {
        e.preventDefault();
        geocodeAddress(zipcode)
        setOpenPanel(true)
        setWithZipCode(true)

    }

    // convert zipcode to Lat and Lng
    async function geocodeAddress (zipcode) {
        try { 
            const apiKey = process.env.REACT_APP_MAPS_API
            const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipcode}&key=${apiKey}`
            const response = await fetch(geocodeUrl)
            const data = await response.json();
            const { results } = data
 
            if (results.length > 0) {
                const { lat, lng } = results[0].geometry.location
                setLat(lat)
                setLng(lng)
                setCenter({lat: lat, lng: lng})

                // setPracticeData(stores)

                // const calculatedDistance =  practiceData && practiceData.map(store => {
                //     const theDistance = calculateDistance(store.lat, store.lng, lat, lng)
                //     const theDistanceTwo = stores.map(item => {
                //         if (item.id === store.id) {
                //             return {...store, distance: Number(theDistance)}
                //         } 
                //     })
                //     return theDistanceTwo
                // })


            

                // const filteredStores = stores && stores.filter(store => calculateDistance(store.lat, store.lng, lat, lng) < 5)

                const filteredStores = stores && practiceData && practiceData.map(store => {
                    const calculatedDistance = calculateDistance(store.lat, store.lng, lat, lng)
                    return {...store, distance: Number(calculatedDistance).toFixed(1)}
                })

                // 1. Add the distance first for all the objects
                // 2. Filter them thats less than 5 miles


                const filteredStoresTwo = filteredStores && filteredStores.filter(store => store.distance < 5).sort((a,b) => a.distance - b.distance)
                
                setFoundStores(filteredStoresTwo)
                setFilterFoundStores(filteredStoresTwo)
            } else {
                setLat(null)
                setLng(null)
            }
        } catch (error) {
            console.log(error)
            setLat(null)
            setLng(null)
        }
    }

    function calculateDistance(lat1, lon1, lat2, lon2) {

        const earthRadius = 6371; // Radius of the earth in kilometers

        const degToRad = (deg) => {
            return deg * (Math.PI / 180);
        };

        const dLat = degToRad(lat2 - lat1);
        const dLon = degToRad(lon2 - lon1);

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(degToRad(lat1)) *
            Math.cos(degToRad(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = earthRadius * c
        const distanceMiles = distance *  0.621371 

        return distanceMiles
    }



    return (
        <div className="panel p-2 p-md-3 border border-dark border-2 rounded-2 mx-2 mx-lg-0" id="panel">
            <div className="controls d-flex flex-column flex-md-row justify-content-between my-4">
                <div className="d-flex justify-content-center border border-dark border-2 rounded-2 text-center mb-2 mb-md-0 p-0">
                    <input  className="tt-panel-input rounded-2" type="text"  placeholder="Enter Zipcode" onChange={handleChange} value={zipcode}/>  
                    <button className="btn " onClick={() => {
                        setZipcode("")
                        setWithZipCode(false)
                        setSelectedValue("all")
                        setFilterFoundStores(stores)
                    }}>X</button>                  
                </div>
                    {/* <input  className="border border-dark border-2 rounded-2 text-center mb-2 mb-md-0" type="text"  placeholder="Enter Zipcode" onChange={handleChange} value={zipcode}/>         */}


                <button className="btn bg-dark text-white px-4" onClick={handleClick} > Search <i className="bi bi-arrow-right fs-5" /> </button>
            </div>

            <select value={selectedValue} class="tt-panel-select form-select" aria-label="Default select example" onChange={(e) => {
                setType(e.target.value)
                setSelectedValue((e.target.value).toLowerCase())
            }}>
                <option value="all">All</option>
                <option value="restaurant">Restaurant</option>
                <option value="retail">Retail</option>
                <option value="event">Event</option>
            </select>
            {/* <p className="px-1">Locations: {foundStores && foundStores.length !==0 ? foundStores.length : foundStores === null ? stores.length : "0" }</p>
            <div className={` results  d-flex flex-column align-items-start`}>
                {foundStores && foundStores.length !== 0 ? foundStores.map((store, index) => (
                    <LocationCard  key={index}  index={index} store={ store } setCenter={setCenter} currIndex={currIndex} setCurrIndex={setCurrIndex}/>
                ))  : foundStores === null ? stores.map((store, index) => (
                    <LocationCard  key={index}  index={index} store={ store } setCenter={setCenter} currIndex={currIndex} setCurrIndex={setCurrIndex}/>
                )) :  <p className=" px-1 text-dark">No results</p> }

            </div> */}
            <p className="px-1">Locations: {filterFoundStores && filterFoundStores.length !==0 ? filterFoundStores.length : filterFoundStores === null ? stores.length : "0" }</p>
            <div className={` results  d-flex flex-column align-items-start`}>
                {filterFoundStores && filterFoundStores.length !== 0 ? filterFoundStores.map((store, index) => (
                    <LocationCard  key={index}  index={index} store={ store } setCenter={setCenter} currIndex={currIndex} setCurrIndex={setCurrIndex}/>
                ))  : filterFoundStores === null ? stores.map((store, index) => (
                    <LocationCard  key={index}  index={index} store={ store } setCenter={setCenter} currIndex={currIndex} setCurrIndex={setCurrIndex}/>
                )) :  <p className=" px-1 text-dark">No results</p> }

            </div>
        </div>
    )
}

export default Panel