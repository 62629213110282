import GoogleMapReact from "google-map-react" 
import { useState } from "react"
import { StoresData } from "../../data/storesdata";
import { GoogleMap, InfoWindow, LoadScript, Marker } from '@react-google-maps/api';
import { useEffect } from "react";

const containerStyle = {
  height: '750px'
};

const onLoad = infoWindow => {
  console.log('infoWindow: ', infoWindow)
}


const Maps = ({children, center, setCenter, stores, currIndex, setCurrIndex, foundStores, setFoundStores, type, setType, withZipCode, setWithZipCode, filterFoundStores, setFilterFoundStores}) => {
    const [active, setActive] = useState(false)
    const [id, setId] = useState("")
    const [infoWindow, setInfoWindow] = useState("")
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (type === "all" && !withZipCode) {
            setFilterFoundStores(stores)
        } else if (type === "restaurant" && !withZipCode) {
            setFilterFoundStores(stores.filter(store => store.type === "restaurant")) 
        } else if (type === "event" && !withZipCode) {
            setFilterFoundStores(stores.filter(store => store.type === "event"))
        } else if (type === "retail" && !withZipCode) {
            setFilterFoundStores(stores.filter(store => store.type === "retail"))
        } else if ( type === "restaurant" && withZipCode) {
            setFilterFoundStores(foundStores.filter(store => store.type === "restaurant"))
        } else if ( type === "event" && withZipCode) {
            setFilterFoundStores(foundStores.filter(store => store.type === "event"))
        } else if ( type === "retail" && withZipCode ) {
            setFilterFoundStores(foundStores.filter(store => store.type === "retail"))
        } else if ( type === "all" && withZipCode ) {
            setFilterFoundStores(foundStores)
        }
    },[ type])

    function details (store, id) { 
        setActive(!active) 
        setInfoWindow(store)
        setId(id)
        setIsOpen(true)
        setCenter({lat: store.coordinates._lat, lng:store.coordinates._long })
        setCurrIndex(id)
    }
 
    return (
        <LoadScript
        googleMapsApiKey={process.env.REACT_APP_MAPS_API}
      >
        <div className="tt-remove-img-css">
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={12}


                >
                        {filterFoundStores ? filterFoundStores.map((store, index) => (
                        <Marker  
                            key={index} 
                            className=""  
                            onClick={() => {
                                setCurrIndex(index)
                                setCenter({lat: Number(store.lat), lng: Number(store.lng) })
                            }}
                            position={{lat: Number(store.lat), lng: Number(store.lng)}} 
                            icon={{
                            url:  store.name.includes("626") ? require("./../../assets/imgs/maps-626-icon2.png") : require("./../../assets/imgs/map-icon.png"),
                            scale: 15,
                            }} >
                                { currIndex === index  && <InfoWindow 
                                    position={{lat: Number(store.lat), lng: Number(store.lng)}} 
                                    onCloseClick={() => setIsOpen(false)}
                                >
                                    <div className="tt-details-card-two">
                                        <div className="d-flex justify-content-between py-1">
                                            <p className="fw-bold fs-6 m-0 text-dark">{store.name}</p>
                                        </div>
                                        <p className="fs-6 text-muted mb-2">{store.address}</p>
                                        <a  className="fs-6" href={store.link} target="_blank">Website</a>
                                    </div>
                                </InfoWindow>}
                        </Marker>

                    )) : 
                    stores && stores
                        .sort((a,  b) => {
                            if (a.name < b.name) {
                                return -1
                            } else if (a.name > b.name) {
                                return 1
                            } else {
                                return 0
                            }
                        })
                        .map((store, index) => (
                            <Marker  
                                key={index} 
                                className=""  
                                onClick={() => {
                                    setCurrIndex(index)
                                    setCenter({lat: Number(store.lat), lng: Number(store.lng) })
                                }}
                                position={{lat: Number(store.lat), lng: Number(store.lng)}} 
                                icon={{
                                url:  store.name.includes("626") ? require("./../../assets/imgs/maps-626-icon2.png") : require("./../../assets/imgs/map-icon.png"),
                                scale: 15,
                                }} >
                                    { currIndex === index  && <InfoWindow 
                                        position={{lat: Number(store.lat), lng: Number(store.lng)}} 
                                        onCloseClick={() => setIsOpen(false)}
                                    >
                                        <div className="tt-details-card-two">
                                            <div className="d-flex justify-content-between py-1">
                                                <p className="fw-bold fs-6 m-0 text-dark">{store.name}</p>
                                            </div>
                                            <p className="fs-6 text-muted mb-2">{store.address}</p>
                                            <a  className="fs-6" href={store.link} target="_blank">Website</a>
                                        </div>
                                    </InfoWindow>}
                            </Marker>
                        ))}
            </GoogleMap>
            {children}
        </div>
      </LoadScript>
    )
}

export default Maps